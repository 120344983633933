import "./App.css";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar/Navbar";
import YoutubeEmbed from "./YoutubeEmbed";

import { SiGooglecolab as Colab } from "react-icons/si";

function App() {
  return (
    <div className="page-container">
      <div className="content-wrap">
        <Helmet>
          <title>Cats vs Dogs</title>
        </Helmet>
        <Navbar />
        <div className="column-container">
          <h1 className="text-content">CSE 455 Final Project</h1>

          <h2 className="text-content">Inspiration</h2>
          <div
            className="row-container"
            style={{ justifyContent: "flex-start" }}
          >
            <div>
              <p className="text-content">
                Image classification is super cool and we wanted to learn more
                about how transfer learning can be used to transform models that
                are trained on large datasets with lots of labels to smaller
                datasets with fewer labels.
              </p>
              <p className="text-content">
                Our inspiration for this project came from looking at random
                Kaggle competition data sets. We stuck with one that caught our
                attention, the{" "}
                <a href="https://www.kaggle.com/c/dog-vs-cat-classification">
                  Cat Vs Dog classification
                </a>
                . We used techniques learned from class to complete this
                project. From our homework assignment on training a
                Convolutional Neural Network (CNN), we used the concepts we
                learned and implemented it in PyTorch to further explore
                classification using CNNs.
              </p>
            </div>
            <img
              src="/img/undraw_decorative_friends_cat.png"
              alt="cat sitting on a windowsill in winter time"
            />
          </div>

          <h2 className="text-content">Approach</h2>
          <div
            className="row-container"
            style={{ justifyContent: "flex-start" }}
          >
            <div>
              <div className="row-container">
                <img
                  src="/img/undraw_relaxing_walk_dog.png"
                  alt="a woman walking her dog in a park with trees"
                />
                <div>
                  <p className="text-content">
                    Our implementation was based on one of the tutorials we did
                    in class for PyTorch. Using this baseline, and with some
                    additional research, we were able to change the data intake
                    process to align with our usage of the Kaggle API, and using
                    the pandas library, we were able to get CSV files as our
                    output, so that we can submit the file to Kaggle in order
                    for it to receive a score.
                  </p>
                  <p className="text-content">
                    For our models, we chose ones that had pre-trained weights
                    in order for us to use transfer learning, since there were
                    multiple forks of ResNet, we decided to see if the different
                    choices between the training datasets they used had any
                    effect on our models.
                  </p>
                </div>
              </div>

              <p className="text-content">
                We also used DarkNet because it was in our tutorial, and did not
                use a pre-trained version just to compare between models that
                are already very well trained and one that is trained from
                scratch and only on the Kaggle training dataset.
              </p>
            </div>
          </div>

          <h2 className="text-content">Models</h2>
          <div
            className="row-container"
            style={{ justifyContent: "flex-start" }}
          >
            <div style={{ width: "100%" }}>
              <p className="text-content">
                <a href="https://pjreddie.com/darknet/">DarkNet</a> - We used a
                modifed version of DarkNet that only has 2 output layers instead
                of 1000 for ImageNet.
              </p>
              <p className="text-content">
                <a href="https://pytorch.org/hub/pytorch_vision_resnet/">
                  ResNet 18
                </a>{" "}
                - We used a pre-trained version of ResNet 18 imported from the
                PyTorch resource hub, and changed the last layer to output into
                2 layers.
              </p>
              <p className="text-content">
                <a href="https://pytorch.org/hub/facebookresearch_semi-supervised-ImageNet1K-models_resnext/">
                  ResNet 18 SWSL
                </a>{" "}
                - We used a pre-trained version of ResNet 18 SWSL imported from
                the PyTorch resource hub, and changed the last layer to output
                into 2 layers.
              </p>
            </div>
            <img
              src="/img/undraw_pet_adoption_cat.png"
              alt="a man standing in front of a browser window with a cat in the video frame"
            />
          </div>

          <h2 className="text-content">Video</h2>
          <div style={{marginBottom: "1rem", marginTop: "1rem"}}>
            <YoutubeEmbed embedId="qinOKVebtQs"/>
          </div>

          <div
            style={{ display: "flex", flexDirection: "row",justifyContent: "center"}}
          >
            <h2 className="text-content">Results</h2>
            <a href="https://colab.research.google.com/drive/1nfZXkJtMGvlRFkFLqv-zvmRT23OGbBxs?usp=sharing">
              <Colab style={{ fontSize: "2.5rem", color: "#14224e", backgroundColor: "#F5B400", padding: ".25rem", borderRadius: ".5rem", boxShadow: "2px 2px"}} />
            </a>
          </div>

          <div style={{ width: "100%" }}>
            <p className="text-content">
              For both ResNet models we ran them for 5 epochs (batch size being
              125) with a learning rate of 0.01, a momentum of 0.9 and decay of
              0.0005. Then for the DarkNet model we used the same momentum,
              decay and batch size as we did with the ResNet models. For our
              first test with the DarkNet we ran it with 15 epochs and then
              increased it to 30 for our second test. Unlike the ResNet models,
              we set the learning rate of the model on a schedule. When we ran
              15 epochs we started with 0.01 then every 5 epoch we decreased the
              learning rate by a tenth. Then, when we ran for 30 epochs, we
              started with .01, after 10 epochs, we set it to .001, and then
              finally at 20 epochs we set it to .0001.
            </p>
            <div className="row-container" style={{ justifyContent: "center" }}>
              <div>
                <img
                  src="/img/darknet_15.png"
                  alt="graph of iteration vs loss of the darknet model running 15 epochs"
                />
                <p className="text-content caption">Darknet (15 Epochs)</p>
              </div>
              <div>
                <img
                  src="/img/darknet_30.png"
                  alt="graph of iteration vs loss of the darknet model running 30 epochs"
                />
                <p className="text-content caption">Darknet (30 Epochs)</p>
              </div>
            </div>
            <div className="row-container" style={{ justifyContent: "center" }}>
              <div>
                <img
                  src="/img/resnet18.png"
                  alt="graph of iteration vs loss of the resnet 18 model running 5 epochs"
                />
                <p className="text-content caption">ResNet 18 (5 Epochs)</p>
              </div>
              <div>
                <img
                  src="/img/resnet18_swsl.png"
                  alt="graph of iteration vs loss of the resnet 18 SWSL model running 5 epochs"
                />
                <p className="text-content caption">
                  ResNet 18 SWSL (5 Epochs)
                </p>
              </div>
            </div>
            <div className="row-container" style={{ justifyContent: "center" }}>
              <div>
                <img
                  src="/img/all_four.png"
                  alt="graph of iteration vs loss of all models combined into one"
                />
                <p className="text-content caption">All Models Comparison</p>
              </div>
            </div>
            <p className="text-content">
              From our results on Kaggle, ResNet18 had the best accuracy of
              .99985. ResNext 18 also did better when it came to loss, starting
              and ending with the lowest start and end losses out of the three
              models, 0.078 and 0.011 respectively.
            </p>
            <p className="text-content">
              The next best model was ResNet18-SWSL, with an accuracy of .98791.
              The loss we see is also the second lowest start and ending loss
              out of the 3 models, 0.087 and 0.021 respectively.
            </p>
            <p className="text-content">
              The DarkNet model did the worst out of all of the models, even
              when increasing the amount of epochs. When we ran it for 15 epochs
              the accuracy ended up being 0.89416
            </p>
            <p className="text-content">
              When we increased the epochs for DarkNet, we can see that it
              performed significantly better than before. The accuracy was
              0.94750, which compared to ResNet18 and ResNet18-SWSL is not as
              good but on its own, but it's an acceptable accuracy. The loss for
              the second test with the DarkNet model started with 0.620 and
              ended with 0.156.
            </p>
            <p className="text-content">
              One reason why the DarkNet model did not perform as well as the
              two ResNet models could be because the ResNet models were already
              pre trained, while the DarkNet model needed to be trained from
              scratch. Since the ResNet models started off pretrained, the
              DarkNet needs to run for more epochs to catch up and perform on
              the same level.
            </p>
            <p className="text-content">
              In the end ResNet18 was the best model, in accuracy, loss and time
              needed to train.
            </p>
          </div>

          <h2 className="text-content">Discussion</h2>
          <div>
            <div className="row-container">
              <img
                src="/img/undraw_good_doggy_dog.png"
                alt="a woman petting a dog in a park"
              />
              <div style={{ width: "100%" }}>
                <h3 className="text-content">
                  What problems did you encounter?
                </h3>
                <p className="text-content">
                  Some problems we encountered throughout doing this project was
                  finding a way to instead load in CIFAR10 data to load in the
                  Kaggle data. This was difficult at first because we did not
                  know how to extract the Kaggle data, and when we were able to
                  unzip the files we needed for training, the dataloader from
                  the tutorial did not work for the data, so we had to implement
                  our own. To help implement this, we drew heavy inspiration
                  from resources that we have found on Google.
                </p>
              </div>
            </div>

            <p className="text-content">
              Another problem we encountered is trying to compute the accuracy.
              In the example pytorch tutorials from class, they used datasets
              that were already built in, so the test data already had the
              correct labels. This was so we could compare it to the labels that
              the model predicted and calculate the accuracy of the model. We
              struggled to figure out how to get the correct labels for our test
              data that we loaded into pytorch, since it did not come with them.
              Then we found out that we had to upload our CSV onto the Kaggle
              website itself. This is because Kaggle was the only one with the
              correct labels for the test data, so by uploading the CSV for each
              model’s data Kaggle did the accuracy calculation themselves by
              comparing our results to the true labels.
            </p>
            <h3 className="text-content">
              Are there next steps you would take if you kept working on the
              project?
            </h3>
            <p className="text-content">
              If we were to keep working on the project, the next steps would be
              to train the models for more epochs, and adjusting the learning
              rate, momentums, and decays to see which would work the best. We
              would've also tried training DarkNet on CIFAR or ImageNet first
              and try using transfer learning with DarkNet.
            </p>
            <p className="text-content">
              Because of the time constraints that we were under, we did not
              have the time to implement and consider other models, one of which
              is EfficientNet, which we would've loved to use, but it was not as
              easy to implement into our code as ResNet.
            </p>
            <h3 className="text-content">
              How does your approach differ from others? Was that beneficial?
            </h3>
            <p className="text-content">
              One way our approach might differ from others is that we used two
              pre-trained models. This is beneficial because it takes less time
              to run the training algorithm, and it gives better results
              compared to models that are trained from scratch.
            </p>
            <p className="text-content">
              Another way our approach might differ is that we ran two tests on
              DarkNet with varying epochs and learning rate curves. This is
              helpful to demonstrate how running more epochs can help improve
              accuracy.
            </p>
          </div>

          <h2 className="text-content">Team</h2>
          <div
            className="row-container"
            style={{ justifyContent: "flex-start" }}
          >
            <div style={{ width: "100%" }}>
              <p className="text-content">
                <a href="https://www.linkedin.com/in/erika-moreno-blackburn-65311a192">
                  Erika Blackburn
                </a>
              </p>
              <p className="text-content">
                <a href="https://www.linkedin.com/in/teresa-lee-171360198/">
                  Teresa Lee
                </a>
              </p>
              <p className="text-content">
                <a href="https://www.tnyyn.com">Tony Yuan</a>
              </p>
            </div>
            <img
              src="/img/undraw_passing_by_dog.png"
              alt="a dog walking past a fire hydrant and peeing on it"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
