import styled, {keyframes} from 'styled-components';

const underlineAnimation = keyframes`
  from {
    text-underline-offset: 0.1rem;
  }

  to {
    text-decoration-color: rgba(223, 239, 202, 1);
    text-underline-offset: 0.3rem;
  }
`

export const Nav = styled.nav`
  background: #0a1128;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  
  z-index: 10;
`

export const NavLink = styled.a`

  color: #DFEFCA;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 1rem 1rem;
  height: 100%;
  cursor: pointer;
  
  text-decoration: underline 0.2rem rgba(255, 249, 165, 0);

  &:hover {
    animation: ${underlineAnimation} 0.2s linear forwards;
  }
`

export const NavMenu = styled.div`
  background: #0a1128;
  display: flex;
  justify-content: space-between;
  align-items: left;
  position: relative;
  z-index: 10;
  vertical-align: middle;
`